import React, { useState, useCallback } from "react"
import Gallery, { Photo } from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

const Bilder = ({ photos }) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const imageRenderer = useCallback(
    ({ index, onClick, margin, direction, left, top, key, photo }) => (
      <div>
        <Photo
          key={key}
          index={index}
          photo={photo}
          left={left}
          top={top}
          onClick={onClick}
          margin={margin}
          direction={direction}
        />
        <small>
          <strong>{photo.titel}</strong>
        </small>
        <small> ({photo.alt})</small>
      </div>
    )
  )

  return (
    <div>
      <Gallery
        photos={photos}
        renderImage={imageRenderer}
        onClick={openLightbox}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel currentIndex={currentImage} views={photos} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default Bilder
