import React from "react"
import Title from "../components/Title"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"
import Bilder from "../components/Bilder"

const photos_pfannen = [
  {
    src: "images/galerie/004.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Müllenbach",
    alt: "Deckung mit Dachpfannen und Ziegel",
    caption: "Deckung mit Dachpfannen und Ziegel",
  },
  {
    src: "images/galerie/005.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Winningen",
    alt: "Deckung mit Dachpfannen und Ziegel",
    caption: "Deckung mit Dachpfannen und Ziegel",
  },
  {
    src: "images/galerie/006.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Cochem-Sehl",
    alt: "Deckung mit Dachpfannen und Ziegel",
    caption: "Deckung mit Dachpfannen und Ziegel",
  },
  {
    src: "images/galerie/007.jpg",
    width: 4,
    height: 3,
    titel: "Münzplatz, Koblenz",
    alt: "Deckung mit Dachpfannen und Ziegel",
    caption: "Deckung mit Dachpfannen und Ziegel",
  },
]

const photos_fenster = [
  {
    src: "images/galerie/007.jpg",
    width: 4,
    height: 3,
    titel: "Münzplatz, Koblenz",
    alt: "Velux-Dachfenster in verschiedenen Kombinationen",
    caption: "Velux-Dachfenster in verschiedenen Kombinationen",
  },
  {
    src: "images/galerie/008.jpg",
    width: 4,
    height: 3,
    titel: "Münzplatz, Koblenz",
    alt: "Velux-Dachfenster in verschiedenen Kombinationen",
    caption: "Velux-Dachfenster in verschiedenen Kombinationen",
  },
]

const photos_schiefer = [
  {
    src: "images/galerie/001.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Neustadt/Weinstraße",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/002.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Neustadt/Weinstraße",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/003.jpg",
    width: 4,
    height: 3,
    titel: "Weißes Haus, Neustadt/Weinstraße",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/009.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Cochem-Cond",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/010.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Cochem-Cond",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/lamp1_a.jpg",
    width: 4,
    height: 3,
    titel: "Domkirche Lampertsheim",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/lamp2_a.jpg",
    width: 4,
    height: 3,
    titel: "Domkirche Lampertsheim",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/lamp3_a.jpg",
    width: 4,
    height: 3,
    titel: "Domkirche Lampertsheim",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/lamp4_a.jpg",
    width: 4,
    height: 3,
    titel: "Domkirche Lampertsheim",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/lamp5_a.jpg",
    width: 4,
    height: 3,
    titel: "Domkirche Lampertsheim",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/012.jpg",
    width: 4,
    height: 3,
    titel: "Backhaus Lohner",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/013.jpg",
    width: 4,
    height: 3,
    titel: "Detailansicht Kamin",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/014.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Cochem-Cond",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/015.jpg",
    width: 4,
    height: 3,
    titel: "Seniorenresidenz, Cochem-Cond",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/016.jpg",
    width: 4,
    height: 3,
    titel: "Seniorenresidenz, Cochem-Cond",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/017.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Alfllen",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/018.jpg",
    width: 4,
    height: 3,
    titel: "Villa Savelsberg, Jülich",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/019.jpg",
    width: 4,
    height: 3,
    titel: "Villa Savelsberg, Jülich",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/020.jpg",
    width: 4,
    height: 3,
    titel: "Villa Savelsberg, Jülich",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/021.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Cochem",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/022.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Cochem",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/023.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Treis",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
  {
    src: "images/galerie/028.jpg",
    width: 4,
    height: 3,
    titel: "Wohnhaus, Treis",
    alt: "Deckung mit Schiefer",
    caption: "Deckung mit Schiefer",
  },
]

const photos_metall = [
  {
    src: "images/galerie/024.jpg",
    width: 4,
    height: 3,
    titel: " Tierpraxis, Dohr ",
    alt: "Deckung mit Metall",
    caption: "Deckung mit Metall",
  },
  {
    src: "images/galerie/025.jpg",
    width: 4,
    height: 3,
    titel: " Tierpraxis, Dohr ",
    alt: "Deckung mit Metall",
    caption: "Deckung mit Metall",
  },
  {
    src: "images/galerie/026.jpg",
    width: 4,
    height: 3,
    titel: " Werkhalle, Treis",
    alt: "Deckung mit Metall",
    caption: "Deckung mit Metall",
  },
  {
    src: "images/galerie/027.jpg",
    width: 4,
    height: 3,
    titel: " Werkhalle, Treis",
    alt: "Deckung mit Metall",
    caption: "Deckung mit Metall",
  },
]

const Index = ({ data }) => {
  return (
    <Layout>
      <SEO title="Projekte" description={data.site.siteMetadata.description} />

      <ServicesContainer>
        <Title title="Galerie und Referenzen " subtitle="Referenzen!" />
        <hr className="services_separator" />
        <TextWrapper>
          <p>
            Hier stellen wir Ihnen eine Auswahl unserer ausgeführten Projekte
            etwas näher vor, um Ihnen einen Einblick in die Qualität unserer
            Arbeit zu geben.
          </p>
          <p>
            <strong>
              Klicken Sie auf eines der Bilder, um es in einer größeren Ansicht
              zu betrachten.
            </strong>
          </p>
          <hr className="services_separator_margin" />
          <Title title="Deckung mit " subtitle="Dachpfannen und Ziegel" />
          <hr className="services_separator_margin" />
          <Bilder photos={photos_pfannen} />

          <hr className="services_separator_margin" />
          <Title title="Dachflächenfenster " subtitle="" />
          <hr className="services_separator_margin" />
          <Bilder photos={photos_fenster} />

          <hr className="services_separator_margin" />
          <Title title="Deckung mit " subtitle="Schiefer" />
          <hr className="services_separator_margin" />
          <Bilder photos={photos_schiefer} />

          <hr className="services_separator_margin" />
          <Title title="Deckung mit " subtitle="Metall" />
          <hr className="services_separator_margin" />
          <Bilder photos={photos_metall} />
        </TextWrapper>
      </ServicesContainer>
    </Layout>
  )
}

export const getIndexData = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
const ServicesContainer = styled.section`
  padding-top: 3rem;

  .services_separator_margin {
    width: 20%;
    margin: 3rem auto;
  }

  .services_separator {
    width: 20%;
    margin: 0 auto;
  }
`

const TextWrapper = styled.div`
  margin-top: 1rem;
  padding: 1rem;

  @media (min-width: 780px) {
    padding: 4rem;
    margin-top: 4rem;
  }
`

export default Index
